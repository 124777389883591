import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=2478fdfe&"
import script from "./Index.vue?vue&type=script&lang=js&"
export * from "./Index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jae/code/ayup/admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2478fdfe')) {
      api.createRecord('2478fdfe', component.options)
    } else {
      api.reload('2478fdfe', component.options)
    }
    module.hot.accept("./Index.vue?vue&type=template&id=2478fdfe&", function () {
      api.rerender('2478fdfe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/update-requests/Index.vue"
export default component.exports