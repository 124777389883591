import { render, staticRenderFns } from "./Edit.vue?vue&type=template&id=02521888&"
import script from "./Edit.vue?vue&type=script&lang=js&"
export * from "./Edit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jae/code/ayup/admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('02521888')) {
      api.createRecord('02521888', component.options)
    } else {
      api.reload('02521888', component.options)
    }
    module.hot.accept("./Edit.vue?vue&type=template&id=02521888&", function () {
      api.rerender('02521888', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/collections/events/Edit.vue"
export default component.exports